var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("URL")
              ]),
              _c("gov-table-cell", { attrs: { break: "" } }, [
                _vm._v(_vm._s(_vm.pageFeedback.url))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Channel")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.formatDateTime(_vm.pageFeedback.created_at)))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Feedback")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.pageFeedback.feedback))])
            ],
            1
          ),
          _vm.pageFeedback.name
            ? _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Name")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.pageFeedback.name))])
                ],
                1
              )
            : _vm._e(),
          _vm.pageFeedback.email
            ? _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Email")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.pageFeedback.email))])
                ],
                1
              )
            : _vm._e(),
          _vm.pageFeedback.phone
            ? _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Phone")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.pageFeedback.phone))])
                ],
                1
              )
            : _vm._e(),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Scheduled for deletion")
              ]),
              _c("gov-table-cell", [
                _vm._v(
                  _vm._s(
                    _vm.formatDate(
                      _vm.autoDeleteDate(_vm.pageFeedback.updated_at)
                    )
                  )
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }