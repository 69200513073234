var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("gov-back-link", { attrs: { to: { name: "admin-index-feedback" } } }, [
        _vm._v("Back to page feedbacks")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-grid-row",
                [
                  _c("vue-headful", {
                    attrs: {
                      title:
                        _vm.appName +
                        " - Page Feedback: " +
                        _vm.pageFeedback.feedback.substr(0, 10) +
                        "..."
                    }
                  }),
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "two-thirds" } },
                    [
                      _c("gov-heading", { attrs: { size: "m" } }, [
                        _vm._v("View page feedback")
                      ]),
                      _c("ck-page-feedback-details", {
                        attrs: { "page-feedback": _vm.pageFeedback }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }